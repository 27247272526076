// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-3-dprinting-js": () => import("./../../../src/pages/3dprinting.js" /* webpackChunkName: "component---src-pages-3-dprinting-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-directors-js": () => import("./../../../src/pages/about/directors.js" /* webpackChunkName: "component---src-pages-about-directors-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-join-our-team-js": () => import("./../../../src/pages/about/join-our-team.js" /* webpackChunkName: "component---src-pages-about-join-our-team-js" */),
  "component---src-pages-about-mission-js": () => import("./../../../src/pages/about/mission.js" /* webpackChunkName: "component---src-pages-about-mission-js" */),
  "component---src-pages-about-team-js": () => import("./../../../src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-deep-learning-apply-js": () => import("./../../../src/pages/deep-learning-apply.js" /* webpackChunkName: "component---src-pages-deep-learning-apply-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-bank-js": () => import("./../../../src/pages/payment-bank.js" /* webpackChunkName: "component---src-pages-payment-bank-js" */),
  "component---src-pages-payment-paytm-js": () => import("./../../../src/pages/payment-paytm.js" /* webpackChunkName: "component---src-pages-payment-paytm-js" */),
  "component---src-pages-research-deep-learning-js": () => import("./../../../src/pages/research/deep-learning.js" /* webpackChunkName: "component---src-pages-research-deep-learning-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-scientific-research-js": () => import("./../../../src/pages/research/scientific-research.js" /* webpackChunkName: "component---src-pages-research-scientific-research-js" */),
  "component---src-pages-scientific-research-apply-js": () => import("./../../../src/pages/scientific-research-apply.js" /* webpackChunkName: "component---src-pages-scientific-research-apply-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-payment-js": () => import("./../../../src/pages/services/payment.js" /* webpackChunkName: "component---src-pages-services-payment-js" */),
  "component---src-pages-services-second-opinion-js": () => import("./../../../src/pages/services/second-opinion.js" /* webpackChunkName: "component---src-pages-services-second-opinion-js" */),
  "component---src-pages-services-telepathology-js": () => import("./../../../src/pages/services/telepathology.js" /* webpackChunkName: "component---src-pages-services-telepathology-js" */),
  "component---src-pages-services-teleradiology-js": () => import("./../../../src/pages/services/teleradiology.js" /* webpackChunkName: "component---src-pages-services-teleradiology-js" */),
  "component---src-pages-thank-you-1-js": () => import("./../../../src/pages/thank-you1.js" /* webpackChunkName: "component---src-pages-thank-you-1-js" */),
  "component---src-pages-thank-you-2-js": () => import("./../../../src/pages/thank-you2.js" /* webpackChunkName: "component---src-pages-thank-you-2-js" */),
  "component---src-pages-thank-you-3-js": () => import("./../../../src/pages/thank-you3.js" /* webpackChunkName: "component---src-pages-thank-you-3-js" */)
}

